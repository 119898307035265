export default function TermPageGer(){
    return(
        <>
            <h2 class="HeadlineAGB">&nbsp;</h2>
            <h2 class="HeadlineAGB">ALLGEMEINE GESCHÄFTSBEDINGUNGEN</h2>
            <p>&nbsp;</p>
            <ul type="square">
              <li class="Link"><a href="../../../../Documents/AGB_e.html" target="_self"><strong>For English Version Click Here</strong></a></li>
            </ul>
            <p>	Diese Vereinbarung gilt zwischen dir, dem Nutzer  dieser Website und der Creative Copyrights UG (Haftungsbeschränkt) mit Sitz in  der Reifenstuelstraße 7, 80469 München, Deutschland, dem/den Eigentümer(n)  dieser Website (im Folgenden &quot;Creative Copyrights&quot;, &quot;wir&quot;,  &quot;uns&quot; oder &quot;unser&quot;).   Mit der ersten Nutzung der Website erklärst du dich damit einverstanden,  diese Bedingungen einzuhalten. Wenn du nicht damit einverstanden bist, an diese  Bedingungen gebunden zu sein, solltest du die Nutzung der Website sofort einstellen.</p>
  <p>&nbsp;</p>
  <p><strong>1.           GEISTIGES  EIGENTUM</strong></p>
  <p>1.1.       Alle  auf der Website enthaltenen Inhalte, sofern sie nicht von den Nutzern  hochgeladen wurden, einschließlich, aber nicht beschränkt auf Texte, Grafiken,  Logos, Symbole, Bilder, Soundclips, Videoclips, Datenzusammenstellungen,  Seitenlayout, zugrunde liegender Code und Software, sind Eigentum von Creative  Copyrights, unseren verbundenen Unternehmen oder anderen relevanten  Dritten.  Durch die weitere Nutzung der  Website erkennst du an, dass dieses Material durch die geltenden deutschen und  internationalen Gesetze zum Schutz des geistigen Eigentums und andere  einschlägige Gesetze geschützt ist.</p>
  <p><br></br>
    1.2.       Es  ist dir nicht gestattet, Material von der Website zu reproduzieren, zu  kopieren, zu verbreiten, zu speichern oder auf andere Weise wiederzuverwenden,  es sei denn, es ist auf der Website anders angegeben oder du hast eine  ausdrückliche schriftliche Genehmigung von Creative Copyrights.</p>
  <p>&nbsp;</p>
  <p><strong>2.           BEZAHLUNGEN</strong></p>
  <p>2.1.       Alle  Zahlungen, die im Zusammenhang mit deinem Antrag auf eine Urheberrechtsbescheinigung  fällig werden, müssen über die Website erfolgen und bis zu dem dir mitgeteilten  Fälligkeitstermin bezahlt werden. </p>
  <p><br></br>
    2.2.       Alle  Zahlungen sind mit den jeweils angegebenen Zahlungsmethoden zu leisten. Du  nimmst zur Kenntnis und erklärst dich damit einverstanden, dass du der  jeweiligen Nutzungsvereinbarung für die Zahlungsmethode eines Dritten  unterliegst. Wir haften nicht für Ausfälle, Unterbrechungen oder Fehler im  Zusammenhang mit der von dir gewählten Zahlungsmethode.</p>
  <p><br></br>
    2.3.       Wir  müssen die Zahlung in voller Höhe in sofort verfügbaren und frei übertragbaren  Geldern erhalten, ohne jegliche Einschränkung, Bedingung, Einbehaltung, Abzug,  Aufrechnung oder Gegenforderung. </p>
  <p>&nbsp;</p>
  <p><strong>3.           HOCHLADEN  VON INHALTEN </strong></p>
  <p>3.1.       Du  sicherst unwiderruflich und bedingungslos zu, dass alle von dir auf unsere  Website hochgeladenen Inhalte mit unserer Datenschutzrichtlinie, dem BDSG und  der DSGVO sowie allen anderen geltenden Gesetzen übereinstimmen.</p>
  <p><br></br>
    3.2.       Du  trägst die volle Verantwortung für deine Inhalte, die du auf unsere Website  hochlädst. Wir sind nicht verantwortlich und haften nicht gegenüber Dritten  für:</p>
  <p><br></br>
    3.3. den Inhalt oder die Richtigkeit von Inhalten  oder Daten, die von dir, von uns in deinem Namen oder von einem anderen Nutzer  unserer Website hochgeladen werden; oder</p>
  <p><br></br>
    3.4. den Verlust von Inhalten oder Daten, die du  uns zur Verfügung gestellt hast. Du solltest alle diese Inhalte und Daten  aufbewahren. </p>
  <p><br></br>
    3.5.       Wir  werden die von dir hochgeladenen Inhalte nur für die Erbringung der Dienste,  die Erfüllung unserer Verpflichtungen aus dieser Vereinbarung und andere Zwecke  verwenden, die in dieser Vereinbarung ausdrücklich festgelegt oder anderweitig  zwischen uns vereinbart wurden. Wir werden die von dir hochgeladenen Inhalte  nicht anderweitig offenlegen oder verbreiten, es sei denn, wir sind gesetzlich,  durch ein zuständiges Gericht oder eine Regierungs- oder Aufsichtsbehörde dazu  verpflichtet. </p>
  <p><br></br>
    3.6.       Wir  haben das Recht, deine Identität gegenüber Dritten offenzulegen, die behaupten,  dass von dir auf unsere Website eingestellte oder hochgeladene Inhalte eine  Verletzung ihrer Rechte nach geltendem Recht darstellen.</p>
  <p><strong>4.           RICHTLINIE  ZUR AKZEPTABLEN NUTZUNG</strong></p>
  <p>4.1.       Du  darfst unsere Website nur für rechtmäßige Zwecke nutzen. Du darfst unsere  Website nicht nutzen:</p>
  <p>4.1.1. in einer Weise, die gegen geltende lokale  oder internationale Gesetze oder Vorschriften verstößt;</p>
  <p><br></br>
    4.1.2. auf eine Art und Weise, die ungesetzlich  oder betrügerisch ist oder einen ungesetzlichen oder betrügerischen Zweck oder  Effekt hat;</p>
  <p><br></br>
    4.1.3. wissentlich Daten zu übermitteln, Material  zu versenden oder hochzuladen, das Viren, Trojaner, Würmer, Zeitbomben,  Keystroke-Logger, Spyware, Adware oder andere schädliche Programme oder  ähnlichen Computercode enthält, die dazu bestimmt sind, den Betrieb von  Computersoftware oder -hardware zu beeinträchtigen.</p>
  <p><br></br>
    4.2.       Wir  machen keine Eigentumsrechte an deinen Inhalten geltend. Du behältst das volle  Eigentum an allen deinen Inhalten und allen geistigen Eigentumsrechten oder  anderen Eigentumsrechten, die mit deinen Inhalten verbunden sind. Wir sind  nicht haftbar für Aussagen oder Darstellungen in deinen Inhalten, die du uns  zur Verfügung gestellt hast.</p>
  <p> <br></br>
    4.3.       Du  bist allein für deine Inhalte verantwortlich und erklärst dich ausdrücklich  damit einverstanden, uns von jeglicher Verantwortung freizustellen und von  jeglichen rechtlichen Schritten gegen uns in Bezug auf deine Inhalte  abzusehen.  </p>
  <p>&nbsp;</p>
  <p><strong>5.           MATERIALIEN  VON DRITTEN</strong></p>
  <p>Du versicherst uns, dass du alle erforderlichen  Genehmigungen für Urheberrechte und andere Rechte an geistigem Eigentum für alle  von dir auf unsere Website hochgeladenen Inhalte eingeholt hast.</p>
  <p>&nbsp;</p>
  <p><strong>6.           PRIVACY</strong></p>
  <p>6.1.       Im  Sinne der geltenden Datenschutzgesetze verarbeitet Creative Copyrights alle  personenbezogenen Daten, die du uns zur Verfügung gestellt hast, in  Übereinstimmung mit unserer Datenschutzrichtlinie.</p>
  <p><br></br>
    6.2.       Du  erklärst dich damit einverstanden, dass du, wenn du uns personenbezogene Daten  über einen Dritten zur Verfügung gestellt hast, (1) alle erforderlichen  Zustimmungen und Hinweise eingeholt hast, um eine rechtmäßige Übermittlung  dieser personenbezogenen Daten an Creative Copyrights zu ermöglichen, und (2)  dass du diesen Dritten unsere Datenschutzrichtlinie zur Kenntnis gebracht hast. </p>
  <p><br></br>
    6.3.       Du  erklärst dich damit einverstanden, Creative Copyrights in Bezug auf alle  Verbindlichkeiten, Strafen, Bußgelder, Urteile oder Kosten, die sich aus der  Nichteinhaltung dieser Anforderungen ergeben, schadlos zu halten.</p>
  <p>&nbsp;</p>
  <p><strong>7.           HAFTUNGSAUSSCHLÜSSE</strong></p>
  <p>7.1.       Creative  Copyrights übernimmt keine Garantie oder Zusicherung dafür, dass die Website  deinen Anforderungen entspricht, dass sie von zufriedenstellender Qualität ist,  dass sie für einen bestimmten Zweck geeignet ist, dass sie nicht die Rechte  Dritter verletzt, dass sie mit allen Systemen kompatibel ist, dass sie sicher  ist und dass alle bereitgestellten Informationen korrekt sind. Wir übernehmen  keine Garantie für bestimmte Ergebnisse bei der Nutzung unseres Dienstes.</p>
  <p><br></br>
    7.2.       Kein  Teil dieser Website stellt eine Beratung dar und der Inhalt dieser Website  sollte nicht als Grundlage für Entscheidungen oder Handlungen jeglicher Art  herangezogen werden.</p>
  <p>&nbsp;</p>
  <p><strong>8.           KEINE  RECHTSBERATUNG</strong></p>
  <p>Creative Copyrights ist weder ein Anwalt noch eine  Anwaltskanzlei. Die zur Verfügung gestellten Materialien sind nicht als  Rechtsberatung gedacht und stellen diese auch nicht dar; und es entsteht kein  Anwalt-Mandanten-Verhältnis zwischen dir und Creative Copyrights oder einem  Mitarbeiter oder Vertreter von Creative Copyrights. Nichts, was an diese  Website übermittelt wird, wird als vertraulich behandelt, es sei denn, dies ist  vorgesehen. </p>
  <p>&nbsp;</p>
  <p><strong>9.           RECHTSWIDRIGE  AKTIVITÄTEN</strong></p>
  <p>Creative Copyrights behält sich das Recht vor,  Beschwerden oder gemeldeten Verstößen gegen unsere Nutzungsbedingungen  nachzugehen und alle Maßnahmen zu ergreifen, die wir für angemessen halten,  einschließlich, aber nicht beschränkt auf die Meldung mutmaßlicher  rechtswidriger Aktivitäten an Strafverfolgungsbehörden, Aufsichtsbehörden oder  andere Dritte und die Weitergabe aller notwendigen oder angemessenen  Informationen an diese Personen oder Einrichtungen in Bezug auf Nutzerprofile,  E-Mail-Adressen, Nutzungshistorie, gepostete Materialien, IP-Adressen und  Verkehrsinformationen.</p>
  <p>&nbsp;</p>
  <p><strong>10.        VERFÜGBARKEIT  DER WEBSITE</strong></p>
  <p><br></br>
    10.1.     Der  Dienst wird &quot;wie besehen&quot; und auf einer &quot;wie  verfügbar&quot;-Basis bereitgestellt.   Wir übernehmen keine Garantie dafür, dass der Dienst frei von Mängeln  und / oder Fehlern sein wird.  Soweit  gesetzlich zulässig, übernehmen wir keine (ausdrückliche oder stillschweigende)  Garantie für die Eignung für einen bestimmten Zweck, die Richtigkeit der  Informationen, die Kompatibilität und die zufriedenstellende Qualität.</p>
  <p><br></br>
    10.2.     Creative  Copyrights übernimmt keine Haftung für Unterbrechungen oder die  Nichtverfügbarkeit der Website, die auf externe Ursachen zurückzuführen sind,  einschließlich, aber nicht beschränkt auf den Ausfall von ISP-Geräten,  Host-Geräten, Kommunikationsnetzwerken, Stromausfällen, Naturereignissen,  Kriegshandlungen oder gesetzlichen Einschränkungen und Zensur.</p>
  <p>&nbsp;</p>
  <p><strong>11.         HAFTUNGSBESCHRÄNKUNG</strong></p>
  <p><br></br>
    11.1.      Wir  haften nicht für die Vollständigkeit, Genauigkeit oder Richtigkeit von  Informationen, die auf unserer Website hochgeladen werden, und von damit  verbundenen Inhalten. Du erklärst dich ausdrücklich damit einverstanden, dass  die Nutzung der Dienste und unserer Website auf dein eigenes Risiko erfolgt. </p>
  <p><br></br>
    11.2.      Du  erklärst dich damit einverstanden, die Dienste, unsere Website und die damit  verbundenen Inhalte nicht für Zwecke des Weiterverkaufs zu nutzen, und wir haften  dir gegenüber weder aus Vertrag, unerlaubter Handlung (einschließlich  Fahrlässigkeit), Verletzung gesetzlicher Pflichten oder anderweitig, die sich  aus oder in Verbindung mit diesen Bedingungen ergeben (einschließlich, aber  nicht beschränkt auf die Nutzung oder die Unmöglichkeit der Nutzung der  Dienste, unserer Website oder einer anderen Website oder Software) für:</p>
  <p><br></br>
    11.2.1. entgangene Gewinne, Verkäufe, Geschäfte  oder Einnahmen;<br></br>
    11.2.2. Betriebsunterbrechung;<br></br>
    11.2.3. den Verlust von erwarteten Einsparungen;<br></br>
    11.2.4. Verlust oder Beschädigung von Daten oder  Informationen;<br></br>
    11.2.5. Verlust von Geschäftsmöglichkeiten,  Firmenwert oder Ruf; oder<br></br>
    11.2.6. sonstige indirekte Verluste oder  Folgeschäden.<br></br>
    11.3.      Keine  Bestimmung in diesen Bedingungen schränkt unsere Haftung ein oder schließt sie  aus für:<br></br>
    11.3.1. Tod oder Personenschäden, die auf unsere  Fahrlässigkeit zurückzuführen sind; <br></br>
    11.3.2. Betrug; und/oder<br></br>
    11.3.3. jede andere Angelegenheit, für die wir  nach geltendem Recht unsere Haftung nicht einschränken oder ausschließen  dürfen.</p>
  <p><br></br>
    11.4.      Unsere  Website dient nicht der Aufbewahrung von Daten, und wir haften nicht für den  Verlust von Daten oder Inhalten. </p>
  <p><br></br>
    11.5.      Diese  Bedingungen legen den vollen Umfang unserer Verpflichtungen und Haftungen in  Bezug auf die Bereitstellung der Dienstleistungen und unserer Website fest.  Sofern nicht ausdrücklich in diesen Bedingungen angegeben, sind wir weder durch  ausdrückliche noch durch stillschweigende Bedingungen, Gewährleistungen,  Zusicherungen oder andere Bestimmungen gebunden. Alle Bedingungen, Garantien,  Zusicherungen oder sonstigen Bestimmungen in Bezug auf die Bereitstellung der  Dienste und unserer Website, die in diesen Bedingungen implizit enthalten sind  oder in diese aufgenommen wurden, werden im größtmöglichen gesetzlich  zulässigen Umfang ausgeschlossen, unabhängig davon, ob es sich um ein Gesetz,  Gewohnheitsrecht oder sonstiges handelt.</p>
  <p>&nbsp;</p>
  <p><strong>12.      KEIN VERZICHT</strong></p>
  <p><br></br>
    Macht eine Partei dieser Geschäftsbedingungen von  einem Recht oder Rechtsmittel keinen Gebrauch, so ist dies nicht als Verzicht  auf dieses Recht oder Rechtsmittel auszulegen.</p>
  <p>&nbsp;</p>
  <p><strong>13.    	 FRÜHERE BEDINGUNGEN UND VERSIONEN</strong></p>
  <p><br></br>
    Bei Widersprüchen zwischen diesen  Geschäftsbedingungen und früheren Versionen haben die Bestimmungen dieser  Geschäftsbedingungen Vorrang, es sei denn, es wird ausdrücklich etwas anderes  angegeben.</p>
  <p>&nbsp;</p>
  <p><strong>14.        MITTEILUNGEN</strong></p>
  <p><br></br>
    Alle Mitteilungen sind uns entweder per Post an  unsere Geschäftsräume (siehe Adresse oben) oder per E-Mail an  business@creativecopyrights.com zu übermitteln.   Eine solche Mitteilung gilt 3 Tage nach der Aufgabe zur Post als  zugegangen, wenn sie per Post verschickt wurde, am Tag des Versands, wenn die  E-Mail an einem Werktag vollständig eingegangen ist, und am nächsten Werktag,  wenn die E-Mail an einem Wochenende oder Feiertag verschickt wurde.</p>
  <p>&nbsp;</p>
  <p><strong>15.        RECHT UND GERICHTSBARKEIT</strong></p>
  <p><br></br>
    Diese Geschäftsbedingungen und die Beziehung  zwischen dir und Creative Copyrights unterliegen dem Recht des Staates Bayern  und Creative Copyrights und du erklärst dich damit einverstanden, dich der  ausschließlichen Gerichtsbarkeit der Gerichte in München zu unterwerfen.</p>
  <p>&nbsp;</p>
  <p><em>März, 2023</em></p>
  <p>&nbsp;</p>
        </>
    )
}