import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import {
    Container, Row, Col, FormGroup, Label, Input, Button
} from 'reactstrap';
import {
    getAllContent, setNewContent
} from '../../api'

const Title = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin: 30px 0;
`;

const TextArea = styled.textarea`
    display: block;
    width: 100%;
    height: 100%;
    min-height: 200px;
    padding: 8px;
`;

export default function GetStartedpage() {
    const [content, setContent] = useState({ page: 'getstartedpage' })

    useEffect(() => {
        getAllContent({ page: 'getstartedpage' })
        .then(({ data }) => setContent({ ...content, ...data[0] }))
    }, [])

    const handleChange = (key, value) => {
        setContent({ ...content, [key]: value })
    }

    const update = () => {
        setNewContent(content)
    }


    return (
        <Container>
            <Row>
                <Col xs="12" md="8">
                    <Title>GetStartedpage content</Title>

                    {FORM_INPUTS.map(({ title, name, type }) => (
                        <FormGroup key={name}>
                            <Label for={name}>{title}</Label>
                            {type
                            ?
                                <TextArea type="text" id={name} defaultValue={content[name]}
                                    onChange={({target}) => handleChange(name, target.value)}/>
                            :
                                <Input type="text" id={name} defaultValue={content[name]}
                                onChange={({target}) => handleChange(name, target.value)}/>
                            }
                        </FormGroup>
                    ))}

                    <Button onClick={update}>Apply</Button>
                </Col>
            </Row>
        </Container>
    )
}

const FORM_INPUTS = [
    {
        title: 'First Headline',
        name: 'headlineFirst'
    },
    {
        title: 'First Text',
        name: 'textFirst',
        type: 'textarea'
    },
    {
        title: 'Second Headline',
        name: 'headlineSecond'
    },
    {
        title: 'Second Text',
        name: 'textSecond',
        type: 'textarea'
    },
    {
        title: 'Third Headline',
        name: 'headlineThird'
    },
    {
        title: 'Third Text',
        name: 'textThird',
        type: 'textarea'
    },
    {
        title: 'Fourth Headline',
        name: 'headlineFourth'
    },
    {
        title: 'Fourth Text',
        name: 'textFourth',
        type: 'textarea'
    },
]
