import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import MainButton, {ButtonClick} from '../../components/MainButton'
import { getPageContent } from '../../api/cms'
import { ButtonWrapper } from '../../components/HomepageText';

const Items = [
    {
        title: 'headlineFirst',
        text: 'textFirst'
    },
    {
        title: 'headlineSecond',
        text: 'textSecond'
    },
    {
        title: 'headlineThird',
        text: 'textThird'
    },
    {
        title: 'headlineFourth',
        text: 'textFourth'
    }
]

const Wrapper = styled.div`
    color: #fff;
    padding: 20px 0;
    margin-bottom: 20px;
`;

const Title = styled.div`
    font-size: 26px;
    font-weight: bold;
    // margin-bottom: 5px;
    // cursor: cursor;
    user-select: none;

    // &:after {
    //     content: '';
    //     display: inline-block;
    //     width: 80px;
    //     height: 35px;
    //     background: url('./assets/chevron.png') no-repeat center / contain;
    //     filter: invert(1);
    //     position: relative;
    //     top: 5px;
    //     transition: .3s ease all;
    // }

    &.toggled:after {
        transform: rotate(90deg);
        transition: .3s ease all;
    }
`;

const NoEmailSendAgain = styled.div`
    color: #000;
    text-align: left;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
`;

const ReadMore = styled.div`
    font-size: 20px;
    text-decoration: underline;
    cursor: pointer;
`;

const Text = styled.div`
    p {
        font-size: 18px;
        line-height: 120%;
        padding: 10px 0 0px;
        white-space: break-spaces;
    }
`;


const GetStarted = () => {
    const [content, setContent] = useState({})
    let i = 0;
    useEffect(() => {
        getPageContent({ page: 'getstartedpage' })
        .then(({ data }) => setContent({ ...content, ...data[0] }))
    }, [])

    return (
        <Wrapper>
            {Items.map(({ title, text }, key) => (<GetStartedItem title={content[title]} text={content[text]} href={i++}/>))}
            <ButtonWrapper>
                <MainButton text="HOW IT WORKS" to="/how-it-works"/>
                <MainButton text="USE CASES" to="/use-cases" />
            </ButtonWrapper>
        </Wrapper>
    )
}

const GetStartedItem = ({ title, text, href }) => {
    const [isVisible, setIsVisible] = useState(false)
    const classname = classnames('headline', { 'toggled': isVisible })

    return (
        <Wrapper>
            <Title className={classname} onClick={()=>setIsVisible(!isVisible)}>{title}</Title>
            <Text>
                <p>{text}{href==2 && <ButtonClick text="click here" onClick={null} to="/pricing"></ButtonClick>}</p>
            </Text>
        </Wrapper>
    )
}

export default GetStarted
