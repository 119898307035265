import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Checkbox from "../../images/Checkbox.png"
import MainButton from '../MainButton'
import { getPageContent } from '../../api/cms'
import classnames from 'classnames';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
    color: #fff;
    padding: 40px 0;
    margin-bottom: 0px;
`;

const CardsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    max-width: 750px;
`;
const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 35px;
    box-sizing: border-box;
    text-align: center;
    width: 100%;
    max-width: 320px;
    height: 400px;
    border-radius: 0px;
    background: rgb(0,88,106);
`;

const Title = styled.div`
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
    cursor: pointer;
    user-select: none;

    // &:after {
    //     content: '';
    //     display: inline-block;
    //     width: 80px;
    //     height: 35px;
    //     background: url('./assets/chevron.png') no-repeat center / contain;
    //     filter: invert(1);
    //     position: relative;
    //     top: 5px;
    //     transition: .3s ease all;
    // }

    &.toggled:after {
        transform: rotate(90deg);
        transition: .3s ease all;
    }
`;

const Text = styled.p`
    line-height: 120%;
    margin-bottom: 15px;
    white-space: break-spaces;
    font-size: 18px;
`;

// const Text = styled.div`
//     p {
//         font-size: 18px;
//         line-height: 120%;
//         padding: 10px 0 0px;
//         white-space: break-spaces;
//     }
// `;

const EuroSign = styled.span`
    font-size: 40px;
    margin-right: 2px;
`;
const Cost = styled.span`
    font-size: 60px;
`;

const CardTitle = styled.div`
    font-size: 32px;
`;
const CardContent = styled.div`
    padding-top: 30px;
    line-height: 120%;
`;
const CardCheckList = styled.div`
    padding-top: 30px;
    text-align: left;
    margin-bottom: 15px;

    >div {
        font-size: 15px;
        margin-bottom: 5px;

        :before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 8px;
            background-image: url(${Checkbox});
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }
`;

const Pricing = () => {
    const [content, setContent] = useState({})
    const classname = classnames('headline', { })
    
    useEffect(() => {
        getPageContent({ page: 'pricing' })
        .then(({ data }) => setContent({ ...content, ...data[0] }))
    }, [])

    return (
        <Wrapper>
            <Title className={classname}>{content.headlineFirst}</Title>

            <Text dangerouslySetInnerHTML={{ __html: content.textFirst }}/>

            <CardsWrapper>
                <Card>
                    <CardTitle>PAY AS YOU GO</CardTitle>

                    <CardContent>
                        <EuroSign>€</EuroSign>
                        <Cost>{content.servicecost}</Cost>
                    </CardContent>

                    <CardCheckList>
                        <div>One File Protection</div>
                        <div>A Certificate</div>
                    </CardCheckList>

                    <MainButton text="GET STARTED" to="/get-started"/>

                </Card>

                <Card>
                    <CardTitle>{content.headlineSecond}</CardTitle>
                    <CardContent>{content.textSecond}</CardContent>

                    <CardCheckList>
                        <div>Multiple Files Protection</div>
                        <div>Individual Certificates per Filing</div>
                        <div>No Timing Constraints</div>
                    </CardCheckList>

                    <MainButton mailto={"mailto:business@creativecopyrights.com?subject=Request for individual offer"} text="contact us"/>

                </Card>
            </CardsWrapper>
        </Wrapper>
    )
}

export default Pricing
