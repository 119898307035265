export default function TermPageEng(){
    return(
        <>
            <h2>&nbsp;</h2>
  <h2><span className="HeadlineAGB">TERMS AND CONDITIONS</span></h2>
  <p>&nbsp;</p>
  <p>This agreement is between you, the user of this website, and Creative Copyrights UG (limited liability) based at Reifenstuelstraße 7, 80469 Munich, Germany, the owner(s) of this website (hereinafter &quot;Creative Copyrights&quot;, &quot;we&quot; , &quot;us&quot; or &quot;our&quot;). By using the website for the first time, you agree to abide by these terms. If you do not agree to be bound by these Terms, you should stop using the Site immediately.</p>
  <p>&nbsp;</p>
  <p><strong>1. INTELLECTUAL PROPERTY</strong></p>
  <p><br></br>
    1.1. All content contained on the Website, unless uploaded by users, including but not limited to text, graphics, logos, icons, images, sound clips, video clips, data compilations, page layout, underlying code and software, is the property of Creative copyrights, our affiliated companies or other relevant third parties. By continuing to use the website, you acknowledge that this material is protected by applicable German and international intellectual property laws and other relevant laws.</p>
  <p><br></br>
    1.2. You are not permitted to reproduce, copy, distribute, store or otherwise reuse any material from the website, unless otherwise stated on the website or unless you have the express written permission of Creative Copyrights.</p>
  <p>&nbsp;</p>
  <p><strong>2. PAYMENTS</strong></p>
  <p><br></br>
    2.1. All payments due in connection with your request for a copyright certificate must be made through the Website and paid by the due date notified to you.</p>
  <p><br></br>
    2.2. All payments are to be made using the specified payment methods. You acknowledge and agree that you are subject to the applicable Third Party Payment Method User Agreement. We are not liable for any failure, interruption or error related to the payment method you have chosen.</p>
  <p><br></br>
    2.3. We must receive payment in full in immediately available and freely transferable funds, without any limitation, condition, withholding, deduction, set-off or counterclaim.</p>
  <p>&nbsp;</p>
  <p><strong>3. UPLOADING CONTENT</strong></p>
  <p><br></br>
    3.1. You irrevocably and unconditionally represent that any content you upload to our website is in compliance with our Privacy Policy, the BDSG and the GDPR and all other applicable laws.</p>
  <p><br></br>
    3.2. You are fully responsible for your content that you upload to our website. We are not responsible and shall not be liable to any third party for:</p>
  <p><br></br>
    3.3. the content or accuracy of any content or data uploaded by you, by us on your behalf, or by any other user of our website; or</p>
  <p><br></br>
    3.4. the loss of content or data that you have provided to us. You should retain all such content and data.</p>
  <p><br></br>
    3.5. We will only use the Content you upload to provide the Services, perform our obligations under this Agreement and other purposes expressly set out in this Agreement or otherwise agreed between us. We will not otherwise disclose or distribute the content you upload, unless we are required to do so by law, a court of competent jurisdiction, or a governmental or regulatory authority.</p>
  <p><br></br>
    3.6. We have the right to disclose your identity to any third party who alleges that any content posted or uploaded by you to our website is a violation of their rights under applicable law.</p>
  <p><strong>4. ACCEPTABLE USE POLICY</strong></p>
  <p><br></br>
    4.1. You may only use our website for lawful purposes. You must not use our website:</p>
  <p><br></br>
    4.1.1. in a way that violates any applicable local or international law or regulation;</p>
  <p><br></br>
    4.1.2. in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect;</p>
  <p><br></br>
    4.1.3. knowingly transmit any data, send or upload any material that contains any viruses, trojan horses, worms, time bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware .</p>
  <p><br></br>
    4.2. We claim no ownership rights in your content. You retain full ownership of all of your Content and any intellectual property or other proprietary rights associated with your Content. We are not liable for any statements or representations in your content that you have made available to us.</p>
  <p><br></br>
    4.3. You are solely responsible for your content and you expressly agree to indemnify us from any liability and to refrain from any legal action against us with respect to your content.</p>
  <p>&nbsp;</p>
  <p><strong>5. THIRD PARTY MATERIALS</strong></p>
  <p><br></br>
    You represent to us that you have obtained all necessary copyright and other intellectual property rights approvals for any content that you upload to our website.</p>
  <p>&nbsp;</p>
  <p><strong>6. PRIVACY</strong></p>
  <p><br></br>
    6.1. In accordance with applicable data protection laws, Creative Copyrights will process any personal information you have provided to us in accordance with our Privacy Policy.</p>
  <p><br></br>
    6.2. You agree that if you have provided us with personal information about a third party, you (1) have obtained all necessary consents and notices to allow lawful transfer of that personal information to Creative Copyrights, and (2) for bringing our privacy policy to the attention of that third party.</p>
  <p><br></br>
    6.3. You agree to indemnify Creative Copyrights from any liability, penalty, fine, judgment, or expense resulting from your failure to comply with these requirements.</p>
  <p>&nbsp;</p>
  <p><strong>7. DISCLAIMER OF WARRANTIES</strong></p>
  <p><br></br>
    7.1. Creative Copyrights makes no guarantee or representation that the website will meet your requirements, that it will be of satisfactory quality, that it is fit for a particular purpose, that it will not infringe the rights of third parties, that it will be compatible with all systems that it is secure and that all information provided is correct. We do not guarantee specific results from using our service.</p>
  <p><br></br>
    7.2. Nothing on this website constitutes advice and the contents of this website should not be relied upon for any decision or action of any kind.</p>
  <p>&nbsp;</p>
  <p><strong>8. NO LEGAL ADVICE</strong></p>
  <p><br></br>
    Creative Copyrights is not an attorney or law firm. The materials provided are not intended and do not constitute legal advice; and no attorney-client relationship will be created between you and Creative Copyrights or any employee or agent of Creative Copyrights. Nothing submitted to this website will be treated as confidential unless it is specifically intended to do so.</p>
  <p>&nbsp;</p>
  <p><strong>9. ILLEGAL ACTIVITIES</strong></p>
  <p><br></br>
    Creative Copyrights reserves the right to investigate any complaints or reported violations of our Terms of Service and to take any action we deem appropriate, including but not limited to reporting suspected illegal activity to law enforcement authorities, regulators or other third parties and disclosing any necessary or appropriate information to such persons or entities in relation to user profiles, e-mail addresses, usage history, posted materials, IP addresses and traffic information.</p>
  <p>&nbsp;</p>
  <p><strong>10. AVAILABILITY OF SITE</strong></p>
  <p><br></br>
    10.1. The Service is provided &quot;as is&quot; and on an &quot;as available&quot; basis. We do not guarantee that the Service will be free from defects and/or errors. To the fullest extent permitted by law, we make no warranties (express or implied) of fitness for a particular purpose, accuracy of information, compatibility and satisfactory quality.<br></br>
    10.2. Creative Copyrights assumes no liability for any interruption or unavailability of the website resulting from external causes including but not limited to failure of ISP equipment, host equipment, communications network, power failure, acts of nature, acts of war or legal restrictions and censorship.</p>
  <p>&nbsp;</p>
  <p><strong>11. LIMITATION OF LIABILITY</strong></p>
  <p><br></br>
    11.1. We are not responsible for the completeness, accuracy or correctness of any information uploaded to our website and any related content. You expressly agree that use of the Services and our website is at your own risk.</p>
  <p><br></br>
    11.2. You agree not to use the Services, our website and related content for resale purposes, and we will have no liability to you in contract, tort (including negligence), breach of statutory duty, or otherwise, arising out of or arising in connection with these Terms (including without limitation your use of, or inability to use, the Services, our website or any other website or software) for:</p>
  <p><br></br>
    11.2.1. lost profits, sales, business or revenue;<br></br>
    11.2.2. business interruption;<br></br>
    11.2.3. the loss of anticipated savings;<br></br>
    11.2.4. loss or corruption of data or information;<br></br>
    11.2.5. loss of business opportunity, goodwill or reputation; or<br></br>
    11.2.6. other indirect loss or consequential damage.<br></br>
    11.3. Nothing in these Terms limits or excludes our liability for:<br></br>
    11.3.1. death or personal injury resulting from our negligence;<br></br>
    11.3.2. Fraud; and or<br></br>
    11.3.3. any other matter for which applicable law does not allow us to limit or exclude our liability.</p>
  <p><br></br>
    11.4. Our website is not a data repository and we will not be held responsible for any loss of data or content.</p>
  <p><br></br>
    11.5. These Terms set out the full extent of our obligations and liabilities in relation to the provision of the Services and our website. Except as expressly stated in these Terms, we are not bound by any condition, warranty, representation or other term, express or implied. Any conditions, warranties, representations or other terms relating to the provision of the Services and our website, implied by or incorporated into these Terms are excluded to the fullest extent permitted by law, whether or not statute , common law or otherwise.</p>
  <p>&nbsp;</p>
  <p><strong>12. NO WAIVER</strong></p>
  <p><br></br>
    The failure of a party to these Terms and Conditions to exercise any right or remedy shall not be construed as a waiver of that right or remedy.</p>
  <p>&nbsp;</p>
  <p><strong>13. PRIOR TERMS AND CONDITIONS</strong></p>
  <p><br></br>
    In the event of any inconsistency between these Terms and Conditions and any previous version, the provisions of these Terms and Conditions shall prevail unless expressly stated otherwise.</p>
  <p>&nbsp;</p>
  <p>14. <strong>NOTICES</strong></p>
  <p><br></br>
    All notices should be sent to us either by post at our offices (see address above) or by email to business@creativecopyrights.com. Such notice shall be deemed received 3 days after posting if sent by post, on the day of posting if the e-mail is received in full on a business day, and on the next business day if the e-mail is received in full on a shipped over the weekend or holiday.</p>
  <p>&nbsp;</p>
  <p><strong>15. LAW AND JURISDICTION</strong></p>
  <p><br></br>
    These terms and conditions and the relationship between you and Creative Copyrights are governed by the laws of the State of Bavaria and Creative Copyrights and you agree to submit to the exclusive jurisdiction of the courts located in Munich.</p>
  <p>&nbsp;</p>
  <p><em>March, 2023</em></p>

        </>
    )
}