import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ApplyDiscount from '../ApplyDiscount'
import { verifyCouponCode } from '../../../api'
import { getPageContent } from '../../../api/cms'
import { raiseToast } from '../../../utils/toast'
import "./Payment.css"
import {Buffer} from 'buffer'
import {ethers} from 'ethers'
import axios from "axios"
import creativeCopyRightsAbis from '../../../abis/Creativecopyrights.json'
import { Link } from "react-router-dom";

let CryptoJS = require("crypto-js");
const {create} = require('ipfs-http-client')
// getting keys and hashs from .env
const projectId = process.env.REACT_APP_INFUARA_PROJECT_ID || "2Jd56y8iXPDg0hSvDSeNPcPYyoG";
const projectSecret = process.env.REACT_APP_INFUARA_PROJECT_SECRET || "8024c31f7782339e9464a0b963dd7566";
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS || "";
const privateKey = process.env.REACT_APP_PRIVATE_KEYS || "";
const paypalClientId = process.env.REACT_APP_PAYPAL_ID || "";
const infuaraRpcUrl = process.env.REACT_APP_INFUARA_RPC_URL || "";
const chainLinkId = process.env.REACT_APP_CHAIN_LINK_ID || "";
const cryptoJsSecretKey = process.env.REACT_APP_CRYPTO_JS_KEY || "";

const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');
const ipfs = create({
	host: 'ipfs.infura.io',
	port: 5001,
	protocol: 'https',
	apiPath: '/api/v0',
	headers: {
		authorization: auth,
	}
});

const Wrapper = styled.div`
    padding: 35px 35px;

    >div:first-child {
        border-bottom: 1px solid #004c4c;
    }
`;

const PaymentOption = styled.div`
    color: #004c4c;
    padding: 15px 0;
`;

const Title = styled.div`
    text-transform: uppercase;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    font-weight: bold;
`;
const Description = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 18px;
`;

const Error = styled.div`
  font-size: 16px;
  font-weight: 600;
//   line-height: 1.4;
  margin-bottom: 18px;
  color:red
`;

const Price = styled.div`
  font-weight: 800;
  font-size: 28px;
`;

const PayPalWrapper = styled.div`
    padding: 20px 0;
`;

const ButtonNew = styled.button`
    background-color: transparent;
    color: white;
    // border: 1px solid white;
    font-size: 16px;
    line-height: 100%;
    text-decoration: underline;
    cursor: pointer;
    color:#000;
    &:hover {
        color: #000;
    }
`;

const InnerWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Payment = ({ email, setActiveStage, name, file, setFileData, transactFee }) => {
    let paypalRef = useRef();
    const [isPayPalLoaded, setIsPayPalLoaded] = useState(false)
    const [couponCode, setCouponCode] = useState(null)
    const [cost, setCost] = useState(0)
    const [checked, isChecked] = useState(false);
    const [isCouponDisabled, setCouponDisabled] = useState(false)
    const [payButton, setPaypalButton] = useState(0);
    const [gasFee, setGasFee] = useState(0);
    
    useEffect(() => {
        getPageContent({ page: 'pricing' })
        .then(({ data }) => {setCost(data[0].servicecost)})
    }, [])

    function isValid() {
        return document.querySelector('#check').checked;
    }

    function onChangeCheckbox(handler) {
        document.querySelector('#check').addEventListener('change', handler);
    }

    function toggleValidationMessage() {
        document.querySelector('#msg').style.display = (isValid() ? 'none' : 'block');
    }

    function toggleButton(actions) {
        return isValid() ? actions.enable() : actions.disable();
    }


    React.useEffect(() => {
        if (isPayPalLoaded) { return }
        if (cost<=0) {return}
        
        window.paypal && window.paypal.Button.render({
            env: 'production',
            //env: 'sandbox',
            client: {
               production: paypalClientId,
            // production: PP_CLIENT_ID
            },
            locale: 'en_US',
            style: {
                label: 'paypal',
                size:  'responsive',    // small | medium | large | responsive
                shape: 'pill',     // pill | rect
                color: 'blue',     // gold | blue | silver | black
                tagline: false    
            },
            // funding: {
            //     allowed: [window.paypal.FUNDING.CREDIT],
            //     disallowed: [window.paypal.FUNDING.CARD]
            // },

            // Enable Pay Now checkout flow (optional)
            commit: true,
            validate: function(actions) {
                toggleButton(actions);
    
                onChangeCheckbox(function() {
                    toggleButton(actions);
                });
            },
    
            onClick: function() {
                toggleValidationMessage();
            },
                        
            onInit: () => {
                setIsPayPalLoaded(true)
            },
            // Set up a payment
            payment: function(data, actions) {
                return actions.payment.create({
                    transactions: [{
                        amount: {
                            total: cost,
                            currency: 'EUR',
                        }
                    }]
                });
            },
            // Execute the payment
            onAuthorize: function(data, actions) {
                return actions.payment.execute().then(function(response) {
                    raiseToast('Transaction completed by ' + response.payer?.payer_info?.email + '!')
                    const reader = new window.FileReader()
                    reader.readAsArrayBuffer(file)
                    reader.onloadend = () => {
                        // execute blockchain transaction
                        uploadFile(Buffer(reader.result), file.type, file.name, file.size)
                    }
                });
            }

        },  '#paypal-button-container');

        
        
        var myEle = document.getElementById("paypal-button-container");
        if(myEle){console.log("hidding the pdf");document.querySelector('#paypal-button-container').style.display = 'none';}

    }, [cost])

    // function added by kashif
    const uploadFile =  async (buffer, type, path, size) => {
        // Add file to the IPFS
        const result = await ipfs.add(buffer)
        if(type === ''){
            type = 'none';
        }
    
        let provider = new ethers.providers.JsonRpcProvider(infuaraRpcUrl, 80001) // 80001
        let walletWithProvider =await new ethers.Wallet(privateKey, provider);
		
		const creativecopyrights = await new ethers.Contract(contractAddress, creativeCopyRightsAbis, walletWithProvider);
		if(creativecopyrights) {
        
			const gas_limit = await creativecopyrights.estimateGas.uploadFile(result.path, result.size, type, path, name);
            const { data } = await axios({
                method: 'get',
                url: 'https://gasstation-mainnet.matic.network/v2'
            })
            const maxFeePerGas = new ethers.utils.parseUnits(
                Math.ceil(data.fast.maxFee) + '',
                'gwei'
            )

            const maxPriorityFeePerGas = ethers.utils.parseUnits(
                Math.ceil(data.fast.maxPriorityFee) + '',
                'gwei'
            )

            let filePath = CryptoJS.AES.encrypt(path, cryptoJsSecretKey).toString();
            let userName = CryptoJS.AES.encrypt(name, cryptoJsSecretKey).toString();
           
			const tx = creativecopyrights.uploadFile(result.path, result.size, type, filePath, userName,{
					maxFeePerGas,
                    maxPriorityFeePerGas
				}).then((data) => {
				setFileData({
					name:name,
					file_name:path,
					file_hash:result.path,
					transactionHash:data.hash
				})
				setActiveStage(2);
			})
			
		}  
    }


    const applyCouponCode = () => {
        verifyCouponCode(couponCode)
        .then((isSucceed) => {
            if (isSucceed) {
                raiseToast('Coupon Code is valid and applied!')

                const discountPercentage = couponCode.split('-')[1]
                const newCost = cost * (100-discountPercentage) / 100
            //    hiding paypal button issue
                let content = document.getElementById('#paypal-button-container');
                document.getElementById(content.childNodes[0].id).style.display = 'none'
               
                setCost(newCost)
                setCouponDisabled(true)

            } else {
                raiseToast('Coupon Code is invalid, try again.')
            }
        })
    }

    const changeChecked = () => {
        if(checked)
            isChecked(false);
        else{
            isChecked(true);
        }
    }

    return (
        <Wrapper className="paymentContainer" >

            <PaymentOption>
                <Title>
                    1 file copyright proof
                    <Price>€ {cost}</Price>
                </Title>
                <Description>Your certificate will be e-mailed to:<br/>{email}</Description>
            </PaymentOption>

            <PaymentOption>
                <Title>
                    &nbsp;
                    <Price>€ {cost}</Price>
                </Title>
            </PaymentOption>

            <ApplyDiscount onClick={applyCouponCode}
                setValue={setCouponCode}
                disabled={isCouponDisabled}/>
            <PaymentOption>
                
                <InnerWrap>
                    <input style={{margin:"-14px 9px 0"}} type="checkbox" id="check" />
                    <Description>I've read and accept the <Link className="footerLink" to="termPageEng" target="_blank"><ButtonNew>Terms & Condition</ButtonNew></Link></Description>
                </InnerWrap>
            </PaymentOption>
            
            <PayPalWrapper id='#paypal-button-container'/>
            
        </Wrapper>
    )
}

export default Payment
