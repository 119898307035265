import { useState } from "react";
import TermPageEng from "./termPageLAgnuage/termPageEng";
import TermPageGer from "./termPageLAgnuage/termPageGer";
import "./style.scss";
const TermPage = () =>{
    const  [pageLanguage,setPageLanguage ] = useState(true)
    return(
        <div style={{color:"black",marginLeft:"50px",marginRight:"50px", padding:"10px 50px", background:"white"}}>
        <label class="switch">
            <input type="checkbox" id="togBtn"/>
            <div class="slider round" onClick={()=>setPageLanguage(!pageLanguage)}>
            {/* <!--ADDED HTML --> */}
            <span class="on">GER/ENG</span>
            <span class="off">ENG/GER</span>
            {/* <!--END--> */}
            </div>
        </label>
          {/* <h2><span className="HeadlineAGB"><img src="../iCloudDrive/CC UG/03_MARKETING/00_CORPORATE DESIGN/00_LOGO/CC_Logo_Black.png" width="156" height="84" alt=""/></span></h2> */}
          {pageLanguage?<TermPageGer/>:<TermPageEng/>}
        
        </div>
    )
}
export default TermPage;