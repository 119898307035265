import axios from "axios"
import { API_URL } from '../config'

const gas_station_api_key = process.env.REACT_APP_GAS_STATION_API_KEY

// Users API
export const verifyEMail = ({ name, email }) => {
    const body = { name, email }

    return axios.post(`${API_URL}/api/auth/signup`, body)
        .then((response) => {
            return response.status === 200;
        });
}

export const verifyConfirmationCode = (confirmationCode) => {

    return axios.get(`${API_URL}/api/auth/verify`, { params: { confirmationCode } })
        .then((response) => {
            return response.status === 200;
        })
        .catch(err => {});
}

export const verifyCouponCode = (couponCode) => {

    return axios.get(`${API_URL}/api/coupon/verify`, { params: { couponCode } })
        .then((response) => {
            return response.status === 200;
        })
        .catch(err => {});
}

export const resendConfirmationEmail = (email) => {

    return axios.get(`${API_URL}/api/auth/re-verify`, { params: { email } })
        .then((response) => {
            return response.status === 200;
        })
        .catch(err => {});
}

// function created by kashif
export const sendPdfEmail = ({ email, base64Pdf, userName , fileName, fileHash, transactionHash }) => {
    const body = { userEmail:email , base64Pdf, userName , fileName, fileHash, transactionHash}
    return axios.post(`${API_URL}/api/auth/send-file-in-email`, body)
        .then((response) => {
            return response.status === 200;
        });
}

export const verifyCertificate = (filterData) => {

    return axios.get(`${API_URL}/api/auth/verify-document`, { params: { filterData } })
        .then((response) => {
            return response;
        })
        .catch(err => { });
}


// get gas fee
export const getGasFee = () => {

    return axios.get(`https://ethgasstation.info/api/ethgasAPI.json?9c49a395eea76ba3de52003d7353a755e1bb5f6f11e5cad94d38fa63d1bc`)
        .then((response) => {
            return response;
        })
        .catch(err => { });
}
